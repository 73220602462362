import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Container from "../components/Container"
import H1 from "../components/H1"
import Button from "../components/Button"

import media from "../styles/media"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-bottom: 8rem;
  
  > h1 {
    margin-bottom: .7rem;
  }

  > p {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
		font-size: 1rem;
		letter-spacing: 0.02875rem;
    line-height: 1.17;
    ${media.greaterThan("tablet")`
      font-size: 1.175rem;
    `}

    ${media.greaterThan("desktop")`
      font-size: 1.175rem;
    `}
  }

  > a {
    margin-top: 2.3rem;
    display: inline-block;
  }
`

const Title = styled.div`
  font-family: RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 6rem;
  color: #EA222E;

  ${media.greaterThan("tablet")`
    font-size: 8rem;
  `}

  ${media.greaterThan("desktop")`
    font-size: 10rem;
  `}
`


const NotFoundPage = () => (
  <Layout transparentHeader>
    <SEO title="404: Not found" />
    <Container>
      <Wrapper>
        <Title>404</Title>
        <H1 variant={`secondary`} color={`dark`}>Whoops! We cannot find the page you were looking for.</H1>
        <p>This may be because the page moved, or does not exist.</p>
        <Button variant={`primary`} href={`/`}>Homepage</Button> 
      </Wrapper>
    </Container>
  </Layout>
)

export default NotFoundPage
